import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";
import {
	ComputerDesktopIcon,
} from '@heroicons/react/24/outline'

import Layout from "../../components/widgets/layout";
import Seo from "../../components/seo";
import useTranslation from "../../hooks/useTranslation";

const WidgetCustomizerPage = () => {
	const { language } = useTranslation();

	const translate = {
		"h1": language === "de" ? "Konfigurieren Sie Ihren Buchungsablauf so, wie Sie ihn brauchen." : "Configure your booking flow the way you need it.",
		"h2": language === "de" ? "-> Buchungsfunktionen" : "-> Booking features",
		"text": language === "de" ? "Sie können zwischen verschiedenen Farbeinstellungen und Verhaltensparametern wechseln." : "You can change between different color settings and behavioural parameters.",
		"link": language === "de" ? "Konfigurieren Sie ein Widget" : "Configure a widget",
	}
	return (
		<Layout id="widgets">
			<Seo
				seo={{
					metaTitle: "Widgets at Rentware",
					metaDescription: "It looks like you got to the Widgets section",
				}}
			/>
			{/* HERO */}
			<div className="p-4 bg-secondary-bg md:p-12 lg:p-16 lg:pt-24">
				<div className="grid gap-6 mx-auto max-w-7xl">
					<div className="z-10 col-start-1 row-start-1 bg-secondary-bg md:max-w-[50%]">
						<div className="relative grid p-4 md:pb-8">
							<h1 className="order-2 divider-b">
								{translate.h1}
							</h1>
							<h2 className="order-1 pb-4">{translate.h2}</h2>
							<p className="order-3">
								{translate.text}
							</p>
							<AnchorLink to="#customizer" className="order-4 underline">
								{translate.link}
							</AnchorLink>
							<div className="absolute top-0 bottom-0 right-0 -z-10 hidden w-56 translate-x-1/2 rounded-3xl bg-secondary-bg opacity-[0.95] shadow-[50px_50px_50px_rgba(0,0,0,0.15)] md:col-start-1 md:row-start-1 md:block"></div>
						</div>
					</div>
					<div className="mt-12 text-right md:col-start-1 md:row-start-1">
						<StaticImage
							src="../../images/configure-widget-screen.jpg"
							className="rounded-3xl md:max-w-[50%]"
							alt=""
						/>
					</div>
				</div>
			</div>
            <div id="customizer" className="bg-secondary-bg">
                <div className="hidden mx-auto max-w-7xl md:pt-8 md:block">
                    <rtr-widget-config></rtr-widget-config>
                </div>
				<div className="px-4 py-12 mx-auto text-lg text-center max-w-7xl md:hidden">
					<ComputerDesktopIcon className="inline w-10 p-3 mr-2 rounded-full bg-primary-font stroke-white" />{language === "de" ? "Wechseln Sie zu einem großen Bildschirm, um den Konfigurator sehen zu können" : "Switch to a big screen to be able to see the customizer"}
				</div>
            </div>
		</Layout>
	);
};

export default WidgetCustomizerPage;
